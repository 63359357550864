import qs from 'qs'
import invert from 'lodash/invert'
import { FIRST_EDITION_SUBDOMAIN, PRODUCTION_API_URL } from './constants'

const participationPhilosophyChangeDate = 1663138800 // Sep-14-2022 00:00:00 PDT

const {
  REACT_APP_SHOPIFY_URL
} = process.env

// 39 week extended courses
const extendedCourseNames = [
  'business.ext',
  'sociology.ext',
  'principlesofeconomics.ext',
  'computerscience-i.ext',
  'calculus.ext',
  'psychology.ext',
  'statistics.ext',
  'collegealgebra.ext',
  'precalculus.ext',
  'financialaccounting.ext',
  'professionalcommunication.ext'
]

const getFlags = () => {
  const queryString = qs.parse(window.location.search?.slice(1))
  const queryParameters = Object.entries(queryString)
  if (!queryParameters?.length) return {}

  const flags = queryParameters.reduce((acc, param) => {
    const [key, value] = param || []
    if (key.indexOf('flag-') !== 0) return acc

    acc[key.replace('flag-', '')] = value.toLowerCase() === 'true'
    return acc
  }, {})

  return flags
}

const getCourseBaseUrl = () => {
  const { NODE_ENV } = process.env
  if (NODE_ENV === 'development') {
    return 'http://localhost:3000'
  }
  return window.location.origin
}

const getApiHost = () => {
  let apiHostFromLocalStorage = localStorage.apiHost
  const apiHostFromURL =
    window.location.search && qs.parse(window.location.search.slice(1)).apiHost

  if (!apiHostFromLocalStorage && !apiHostFromURL) {
    return PRODUCTION_API_URL
  }

  if (apiHostFromURL) {
    localStorage.apiHost = apiHostFromURL
    apiHostFromLocalStorage = localStorage.apiHost
  }

  return apiHostFromLocalStorage
}

export const getCourseIds = () => {
  return {
    test: 'test-course',
    token: 'ckv72bcw70000a2t95qftcx6r',
    calculus: 'b227c462-332c-40e0-8735-ea3af6f11661',
    'preview.calculus': 'ckp8vzbw600003h5vzfuhfdcr',
    psychology: '1e2f466d-049a-41e7-af53-74afbfa9d87b',
    'preview.psychology': 'ckppte45z00003g5vsapg3kuh',
    'y.calculus': 'ea88ffd3-5c59-49d5-89b4-b9f009dde9ac',
    statistics: 'ckdampe3b00003g5m6l85b1s5',
    'preview.statistics': 'ckppugm1s00003g5vzj24w075',
    philosophy: 'ckgqsu5lf00003h5lzot6as6x',
    'collegewriting-i': 'cku4ayye700003g5vsb6jgmhb',
    'collegewriting-ii': 'cl31r155p00003f63wxa1sq4z',
    'computerscience-i': 'cl0sd8la200003e62p8cxq2ey',
    'physics-i': 'cl0h6maex00003e625n0dtois',
    'preview.computerscience-i': 'cl1fv7i7h00003e62vuzo788m',
    managerialaccounting: 'cl0h7sg9f00003e620m2mlma8',
    'preview.collegewriting-i': 'cku73ehlx00003g5vpm96sq24',
    business: 'cku4ayarl00003g5v8zya64ih',
    'preview.business': 'cl2upq5jf00003g63mrfli9gh',
    collegealgebra: 'ckw6tiqy100003e5xexynd0dz',
    'preview.philosophy': 'ckppvs4og00003g5vt00g596a',
    'preview-marketing.philosophy': 'ckrz50bj500003h5v6n9qyzcy',
    microeconomics: 'ckiawlgvw00003h5o8c6u6rog',
    'preview.microeconomics': 'ckppvyng500003g5vnn5vt6fj',
    precalculus: 'ckp356yiy00003h5vpz3fbu1h',
    'preview.precalculus': 'cku73ipcg00003g5vb5x06hjb',
    principlesofeconomics: 'ckxyytpau00003f5xhe3sf4xj',
    'preview.principlesofeconomics': 'cl1l0bnk200003e627p3biscw',
    professionalcommunication: 'cl8929un900003b68j8k16w6a',
    'professionalcommunication.plus': 'clg6ywvo000003b676omcaa9t',
    macroeconomics: 'ckp3587wu00003h5v6jfbv37v',
    'preview.macroeconomics': 'cku73hcje00003g5vqjlpojpt',
    financialaccounting: 'ckp359ieh00003h5vm158kdt0',
    collegesuccess: 'ckyp0xzra00003e5zdb9d8mta',
    sociology: 'ckp3592gx00003h5vsd5ijmp9',
    astronomy: 'ckdf2158p00003g5milp6mvj8',
    barnardhighlights: 'cktt8s66100003g5v8872yin9',
    'preview.financialaccounting': 'cky8w5w0s00003e5xalquzhzv',
    'preview.sociology': 'cky8w79k100003e5xdwhztphl',
    'preview.collegealgebra': 'cky8w87y000003e5xy67h8pvz',
    'preview.astronomy': 'ckppvq9q200003g5vhcz1utxu',
    content: 'cl2grnv7z00003f6302c1ccbf',
    'calculus.plus': 'cl8chwjku00003b68m0uulv59',
    'computerscience-i.plus': 'cla70y6pd00003b68xl5o929f',
    'collegealgebra.plus': 'cl8cc3qps00003b68vf2xvbx2',
    'collegewriting-i.plus': 'cl8bpkss900003b68lww2m8az',
    'collegewriting-ii.plus': 'clg6yvuyc00003b67eoyh7lz1',
    'astronomy.plus': 'cl9ipiyvr00003b68940h4yf7',
    'business.plus': 'cl8chrori00003b68iatqg9ds',
    'financialaccounting.plus': 'cl8cicil200003b686pscg7nt',
    'macroeconomics.plus': 'cl8cifhox00003b683k6qy7gk',
    'microeconomics.plus': 'cl8cieub000003b68hturuo2t',
    'philosophy.plus': 'cl8cig0e700003b68mgbqnry2',
    'psychology.plus': 'cl8chvsoy00003b68m0t56gfv',
    'sociology.plus': 'cl8cib1gy00003b682ct750ba',
    'statistics.plus': 'cl8ci9dna00003b684igglvbn',
    'precalculus.plus': 'cl8d5dbhz00003b689avjvmsv',
    'principlesofeconomics.plus': 'cl8cigudr00003b68gdw3vcxn',
    'business.ext': 'clix6ozhf00003b67f9cfxryn',
    'sociology.ext': 'clixipgk800003b6727vle7hi',
    'principlesofeconomics.ext': 'clixiovxz00003b671j9lggjw',
    'computerscience-i.ext': 'clixingmb00003b67f9nlibtx',
    'calculus.ext': 'clj65g8fi00003b670xricu75',
    'psychology.ext': 'clixio9dp00003b67fimpf4h2',
    'statistics.ext': 'clix6t3q600003b67l08i0ljb',
    'collegealgebra.ext': 'clix6s4i500003b67roqr1l1p',
    'precalculus.ext': 'clix6q93y00003b67mbuz6lm4',
    'financialaccounting.ext': 'clix6mm3e00003b67x74owz2b',
    'professionalcommunication.ext': 'clixiqdxw00003b67c8r80fb3',
    'itsupport.cert': 'cljst951400003b69tq4jnltv',
    'projectmanagement.cert': 'clieyyjpp00003b69h8dnhmtu',
    'salesoperations.cert': 'clgclvp9o00003b675vmu4250',
    'digitalmarketing-i.cert': 'clgcltmgf00003b675y2hecxi',
    'dataanalytics-ii.cert': 'cloipjvx900003b6c9irc9j7d',
    'digitalmarketing-ii.cert': 'cloipl0py00003b6cxbtn9t2s',
    'dataanalytics-i.cert': 'clgb29uhc00003b67wtuupjqy'
  }
}

export const additionalCourseIds = {
  'collegewriting-i.plus': 'clpx286t000013b6buj1q8rgq' // CWI GGU V2
}

const courseIds = getCourseIds()

function courseIdToName (id) {
  const courseName = invert(getCourseIds())[id]
  if (!courseName) return invert(additionalCourseIds)[id]
  return courseName
}

function getCourseIcon (courseId) {
  const courseName = courseIdToName(courseId)?.replace('.plus', '')
  return {
    calculus: 'images/icons/calculus_I.svg',
    'calculus-ii': 'images/icons/calculus_I.svg',
    psychology: 'images/icons/psychology.svg',
    statistics: 'images/icons/statistics.svg',
    astronomy: 'images/icons/astronomy.svg',
    precalculus: 'images/icons/precalculus.svg',
    'computerscience-i': 'images/icons/precalculus.svg',
    'physics-i': 'images/icons/precalculus.svg',
    managerialaccounting: 'images/icons/precalculus.svg',
    collegealgebra: 'images/icons/precalculus.svg',
    collegesuccess: 'images/icons/precalculus.svg',
    professionalcommunication: 'images/icons/precalculus.svg',
    principlesofeconomics: 'images/icons/Macroeconomics.svg',
    macroeconomics: 'images/icons/Macroeconomics.svg',
    financialaccounting: 'images/icons/financial-accounting.svg',
    sociology: 'images/icons/sociology-white.svg',
    business: 'images/icons/business-white.svg',
    'collegewriting-i': 'images/icons/college-writing.svg',
    'collegewriting-ii': 'images/icons/college-writing.svg',
    philosophy: 'images/icons/philosophy.svg',
    microeconomics: 'images/icons/Microeconomics.svg',
    'y.calculus': 'images/icons/calculus.svg'
  }[courseName] || 'images/icons/place-holder-course-icon-white.svg'
}

function getCourseBaseUrlById (courseId) {
  const courseName = courseIdToName(courseId)
  const betaUrl = `https://${courseName}.${FIRST_EDITION_SUBDOMAIN}.outlier.org`
  const defaultUrl = `https://${courseName}.outlier.org`
  const defaultCourses = [
    'calculus',
    'psychology',
    'y.calculus',
    'astronomy',
    ...extendedCourseNames,
    'itsupport.cert',
    'dataanalytics-i.cert',
    'salesoperations.cert',
    'digitalmarketing-i.cert',
    'projectmanagement.cert',
    'dataanalytics-ii.cert',
    'digitalmarketing-ii.cert'
  ]
  return defaultCourses.includes(courseName) || courseName?.includes('.plus')
    ? defaultUrl
    : betaUrl
}

function isCSCourse (courseId) {
  const {
    'computerscience-i': cs,
    'computerscience-i.plus': csPlus,
    'computerscience-i.ext': csExt
  } = getCourseIds()
  return [cs, csPlus, csExt].includes(courseId)
}

function hasStudentProgressUpdate () {
  const flags = getFlags()
  const studentProgressUpdate = flags.studentProgressUpdate
  return !!studentProgressUpdate
}

function hasAuditQuiz () {
  const flags = getFlags()
  const auditQuiz = flags.auditQuiz
  return !!auditQuiz
}

function hasAcademicIntegrity () {
  const flags = getFlags()
  const academicIntegrity = flags.academicIntegrity
  return !!academicIntegrity
}

// a function to retry loading a chunk to avoid chunk load error for
// out of date code
const lazyRetry = function (componentImport) {
  return new Promise((resolve, reject) => {
    const hasRefreshed = JSON.parse(
      window.sessionStorage.getItem('retry-lazy-refreshed') || 'false'
    )
    componentImport().then((component) => {
      window.sessionStorage.setItem('retry-lazy-refreshed', 'false')
      resolve(component)
    }).catch((error) => {
      if (!hasRefreshed) {
        window.sessionStorage.setItem('retry-lazy-refreshed', 'true')
        return window.location.reload()
      }
      reject(error)
    })
  })
}

const config = {
  getFlags: getFlags,
  courseIds,
  courseIdToName,
  participationPhilosophyChangeDate,
  courseNames: invert(courseIds),
  courseBaseUrl: getCourseBaseUrl(),
  getApiHost,
  shopifyUrl: REACT_APP_SHOPIFY_URL || 'https://outlier-us.myshopify.com/',
  getCourseBaseUrlById,
  isCSCourse,
  getCourseIcon,
  hasStudentProgressUpdateFlag: hasStudentProgressUpdate(),
  hasAuditQuizFlag: hasAuditQuiz(),
  hasAcademicIntegrityFlag: hasAcademicIntegrity(),
  lazyRetry
}

export default config
