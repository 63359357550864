export const INTENSIVE_COHORT_DURATION = 8
export const EXTENDED_COHORT_DURATION = 39
export const COHORT_TYPES = {
  standard: 'Standard',
  intensive: 'Intensive',
  extended: 'Extended',
  audit: 'Audit',
  extendedAudit: 'Audit - EXT'
}

export const ENROLLED = 'Enrolled'
export const COMPLETED = 'Completed'
export const IN_PROGRESS = 'In Progress'
// setting 95 instead of 100 to differentiate final week and complete status
export const FINAL_WEEK_PERCENTAGE = 95

export const cohortStates = [
  { value: ENROLLED, label: ENROLLED },
  { value: IN_PROGRESS, label: IN_PROGRESS },
  { value: COMPLETED, label: COMPLETED }
]

export const DEFAULT_DEADLINES_IN_WEEKS = {
  dropStandard: 2,
  dropIntensive: 1,
  withdrawStandard: 9,
  withdrawIntensive: 4
}
