import React, { useState, useEffect, useContext } from 'react'

import createClient from './client'
import config from '../../config'
import api from '../../api'
import { FullStoryAPI } from 'react-fullstory'

import { PARTNERSHIPS_MAIL_ADDRESS } from '../../constants'
import { isOutlierAccount } from '../../utilities/user'

const DEFAULT_REDIRECT_CALLBACK = appState => {
  window.history.replaceState({}, document.title, appState?.targetUrl || window.location.pathname)
}

export const Auth0Context = React.createContext()

export const useAuth0 = () => useContext(Auth0Context)

const Auth0Provider = ({
  children,
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState()
  const [user, setUser] = useState()
  const [auth0Client, setAuth0] = useState()
  const [loading, setLoading] = useState(true)
  const [redirect, setRedirect] = useState(false)
  const [popupOpen, setPopupOpen] = useState(false)

  useEffect(() => {
    const initAuth0 = async () => {
      const auth0FromHook = await createClient()
      setAuth0(auth0FromHook)
      if (window.location.search.includes('code=')) {
        let appState = ''
        try {
          const res = await auth0FromHook.handleRedirectCallback()
          appState = res.appState
        } catch (e) {
        }
        onRedirectCallback(appState)
      }

      const isAuthenticated = await auth0FromHook.isAuthenticated()
      setIsAuthenticated(isAuthenticated)

      if (isAuthenticated) {
        const user = await auth0FromHook.getUser()
        const isAdmin = isOutlierAccount(user?.email)
        const searchParams = new URLSearchParams(window.location.search)
        const emailVerifiedParam = searchParams.get('email-verified')
        const shouldVerifyUser = !user.email_verified &&
          (isAdmin || emailVerifiedParam)

        if (shouldVerifyUser) {
          try {
            const auth0Response = await api.updateAuth0User({ email_verified: true })
            if (auth0Response.data?.email_verified) {
              await api.sendWelcomeEmail({ from: PARTNERSHIPS_MAIL_ADDRESS, to: user.email })
              window.location.href = window.location.origin
            }
          } catch (error) {
            console.error(error)
          }
        }

        const [permissionsResponse, partnerIdResponse] = await Promise.all([
          api.getAccessPermissions(),
          api.getPartnerId()
        ])
        const userId = partnerIdResponse.data?.studentId || user.email
        setUser({ ...user, userId, active: !!permissionsResponse.data?.active })
        initFullStory(userId)
      }

      setLoading(false)
      setRedirect(true)
    }
    initAuth0()
    // eslint-disable-next-line
  }, [])

  const initFullStory = (userId) => {
    FullStoryAPI('identify', userId, {
      // Real displayName is not passed to FS in order to limit exposure of PII.
      // As FS needs either displayName or email, first one is passed with the user id
      displayName: userId,
      studentId_str: userId
    })
  }

  const loginWithPopup = async (params = {}) => {
    setPopupOpen(true)
    try {
      await auth0Client.loginWithPopup(params)
    } catch (error) {
      console.error(error)
    } finally {
      setPopupOpen(false)
    }
    const user = await auth0Client.getUser()
    setIsAuthenticated(true)
    setUser(user)
  }

  const handleRedirectCallback = async () => {
    setLoading(true)
    await auth0Client.handleRedirectCallback()
    setLoading(false)
    setIsAuthenticated(true)
  }

  if (!redirect) return null
  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        user,
        loading,
        popupOpen,
        loginWithPopup,
        handleRedirectCallback,
        getIdTokenClaims: (...p) => auth0Client.getIdTokenClaims(...p),
        loginWithRedirect: (...p) => auth0Client.loginWithRedirect(...p),
        getTokenSilently: (...p) => auth0Client.getTokenSilently(...p),
        getTokenWithPopup: (...p) => auth0Client.getTokenWithPopup(...p),
        logout: () => {
          const { courseBaseUrl } = config
          auth0Client.logout({
            returnTo: courseBaseUrl
          })
        }
      }}
    >
      {children}
    </Auth0Context.Provider>
  )
}

export default Auth0Provider
