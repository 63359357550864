import React, { lazy } from 'react'
import { Switch } from 'react-router-dom'
import PublicRoute from './components/Route/PublicRoute'
import WritingGradeCenterProvider from './contexts/WritingGradeCenterContext'
import config from './config'
import RedirectComponent
  from './components/RedirectComponent/RedirectComponent'
const { lazyRetry } = config

const TermsOfUse = lazy(() => lazyRetry(
  () => import('./screens/TermsOfUse/TermsOfUse')
))
const PrivacyPolicy = lazy(() => lazyRetry(
  () => import('./screens/PrivacyPolicy/PrivacyPolicy')
))
const Dashboard = lazy(() => lazyRetry(
  () => import('./screens/Dashboard/Dashboard')
))
const EnrollStudents = lazy(() => lazyRetry(
  () => import('./screens/EnrollStudents/EnrollStudents')
))
const EnrollmentManagement = lazy(() => lazyRetry(
  () => import('./screens/EnrollmentManagement/EnrollmentManagement')
))
const AddTokens = lazy(() => lazyRetry(
  () => import('./screens/AddTokens/AddTokens')
))
const RequestInvoice = lazy(() => lazyRetry(
  () => import('./screens/RequestInvoice/RequestInvoice')
))
const StudentProgress = lazy(() => lazyRetry(
  () => import('./screens/StudentProgress/StudentProgress')
))
const GradeReport = lazy(() => lazyRetry(
  () => import('./screens/StudentProgress/GradeReport/GradeReport')
))
const StudentGradeReport = lazy(() => lazyRetry(
  () => import('./screens/StudentProgress/StudentGradeReport/StudentGradeReport')
))
const WritingGradeCenter = lazy(() => lazyRetry(
  () => import('./screens/WritingGradeCenter/WritingGradeCenter')
))

const ProgramLevelOutcomes = lazy(() => lazyRetry(
  () => import('./screens/ProgramLevelOutcomes/ProgramLevelOutcomes')
))

const DownloadReports = lazy(() => lazyRetry(
  () => import('./screens/ProgramLevelOutcomes/DownloadReports/DownloadReports')
))

const AssessmentPage = lazy(() => lazyRetry(
  () => import('./screens/ProgramLevelOutcomes/Assessment/AssessmentPage')
))

const StudentSubmission = lazy(() => lazyRetry(
  () => import('./screens/WritingGradeCenter/StudentSubmission/StudentSubmission')
))

const CourseMaterials = lazy(() => lazyRetry(
  () => import('./screens/CourseMaterials/CourseMaterials')
))

const Announcements = lazy(() => lazyRetry(
  () => import('./screens/Announcements/Announcements')
))

const AssessmentExtensions = lazy(() => lazyRetry(
  () => import('./screens/AssessmentExtensions/AssessmentExtensions')
))

const AddExtension = lazy(() => lazyRetry(
  () => import('./screens/AssessmentExtensions/AddExtension/AddExtension')
))

const UnlockExams = lazy(() => lazyRetry(
  () => import('./screens/UnlockExams/UnlockExams')
))

const AcademicIntegrity = lazy(() => lazyRetry(
  () => import('./screens/AcademicIntegrity/AcademicIntegrity')
))

const AppRouter = () => {
  const {
    announcements,
    plos: plosFlag,
    academicIntegrity: hasAcademicIntegrityFlag
  } = config.getFlags()

  return (
    <Switch>
      <PublicRoute exact path='/course-download-redirect/:slug' component={RedirectComponent} />
      <PublicRoute exact path='/terms-of-use' component={TermsOfUse} />
      <PublicRoute exact path='/privacy-policy' component={PrivacyPolicy} />
      <PublicRoute path='/' exact component={Dashboard} />
      {announcements && <PublicRoute path='/announcements' component={Announcements} />}
      <PublicRoute exact path='/assessment-extensions' component={AssessmentExtensions} />
      <PublicRoute exact path='/assessment-extensions/create' component={AddExtension} />
      <PublicRoute path='/enroll-students' component={EnrollStudents} />
      <PublicRoute path='/enrollment-management' component={EnrollmentManagement} />
      <PublicRoute path='/add-tokens' component={AddTokens} />
      <PublicRoute path='/request-invoice' component={RequestInvoice} />
      <PublicRoute exact path='/student-progress' component={StudentProgress} />
      <PublicRoute exact path='/student-progress/:cohortId' component={GradeReport} />
      <PublicRoute path='/course-materials' component={CourseMaterials} />
      <PublicRoute
        path='/student-progress/:courseId/:courseName/:cohortId/:cohortName/:studentEmail'
        component={StudentGradeReport}
      />
      <PublicRoute path='/writing-grade-center' component={WritingGradeCenter} />
      <PublicRoute path='/unlock-exams' component={UnlockExams} />
      {hasAcademicIntegrityFlag &&
        <PublicRoute path='/academic-integrity' component={AcademicIntegrity} />}
      {plosFlag &&
        <>
          <PublicRoute exact path='/program-level-outcomes/download-reports' component={DownloadReports} />
          <PublicRoute exact path='/program-level-outcomes' component={ProgramLevelOutcomes} />
        </>}
      <PublicRoute
        path='/plo-assessment/:assessmentType/:courseId/:cohortName/:assignmentUUID/:studentEmail/:sectionUUID?'
        component={AssessmentPage}
      />

      <WritingGradeCenterProvider>
        <PublicRoute
          path='/student-submission/:courseId/:cohortName/:assignmentUUID/:studentEmail'
          component={StudentSubmission}
        />
      </WritingGradeCenterProvider>
      <PublicRoute path='*' component={Dashboard} />
    </Switch>
  )
}

export default AppRouter
